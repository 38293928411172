<template>
  <el-container class="h-full">
    <el-container>
      <el-main>
        <div style="height: 50px; ">
          <page-header
            style="max-width: 90%; padding-left: 0.7rem"
            :title="__('Loaded Configuration')"
          />
        </div>
        <el-tabs
          v-model="activeName"
          style="margin-top: 10px; padding:1.5rem 1.5rem 2rem 0.7rem;"
        >
          <el-tab-pane :label="__('App Info')" name="app-info">
            <app-info :active-tab="activeName" :content="contents"> </app-info>
          </el-tab-pane>
          <el-tab-pane :label="__('Back End')" name="back-end">
            <back-end :active-tab="activeName" :content="contents"> </back-end>
          </el-tab-pane>
          <el-tab-pane :label="__('Front End')" name="front-end">
            <front-end :active-tab="activeName" :content="contents">
            </front-end>
          </el-tab-pane>
          <el-tab-pane :label="__('Interaction')" name="botman">
            <botman :active-tab="activeName" :content="contents"> </botman>
          </el-tab-pane>
        </el-tabs>
      </el-main>
    </el-container>
    <el-aside class="form-aside relative" :width="`${asideWidth}px`">
      <div class="aside-content">
        <div style="font-size: 14px;padding: 30px 15px;color: #A0A8B5">
          {{ asideText }}
        </div>
      </div>
      <slot name="form"></slot>
    </el-aside>
  </el-container>
</template>
<script>
import { mapState, mapActions } from "vuex";
import backEnd from "./pages/backEnd";
import frontEnd from "./pages/frontEnd";
import botman from "./pages/botman";
import appInfo from "./pages/appInfo";
import PageHeader from "@/components/PageHeader";

export default {
  name: "ConfigurationIndex",
  components: {
    PageHeader,
    backEnd,
    frontEnd,
    appInfo,
    botman
  },
  props: {
    formAction: {
      default: ""
    },
    asideText: {
      default: ""
    },
    asideButtonText: {
      default: __("Create")
    },
    asideWidth: {
      type: Number,
      required: false,
      default: 274
    }
  },
  data() {
    return {
      activeName: "app-info"
    };
  },

  computed: {
    ...mapState("loadedconfigurations", {
      contents: state => state.configurations
    })
  },

  methods: {
    ...mapActions("loadedconfigurations", {
      getLoadedConfigurations: "getLoadedConfigurations"
    })
  },
  async created() {
    await this.getLoadedConfigurations();
  }
};
</script>

<style lang="scss" scoped>
.h-full {
  height: 100%;
}

.aside-content {
  position: absolute;
  top: 45%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  width: 100%;
}

.form-aside {
  background: white;
  border-left: #f5f5f8 1px solid;
}
</style>
