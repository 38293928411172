<template>
  <div style="padding: 0 36px;">
    <ul id="example-1">
      <li>
        {{ __("STUDIO FRONTEND RELEASE TAG:") }}
        {{ this.frontEndReleaseVersion }}
      </li>
      <li>
        {{ __("STUDIO BACKEND RELEASE TAG:") }}
        {{ contents.back_end.STUDIO_BACKEND_RELEASE_TAG }}
      </li>
      <li>
        {{ __("STUDIO INTERACTION RELEASE TAG:") }}
        {{ contents.botman.STUDIO_INTERACTION_RELEASE_TAG }}
      </li>
    </ul>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "appInfo",
  props: {
    activeTab: {
      type: String,
      required: false,
      default: ""
    }
  },
  computed: {
    ...mapState("loadedconfigurations", {
      isLoading: state => state.isLoading,
      contents: state => state.configurations
    }),

    frontEndReleaseVersion() {
      return process.env.VUE_APP_STUDIO_FRONTEND_TAG;
    }
  }
};
</script>

<style lang="scss" scoped>
$content-theme-color: var(--theme-color) !default;
$content-theme-hover-color: var(--theme-hover-color) !default;
$content-theme-row-hover-color: var(--theme-row-hover-color) !default;
$content-theme-outline-color: var(--theme-outline-color) !default;
@import "~@/styles/content-edit-info.scss";
@import "~@/styles/button.scss";
@import "~@/styles/form_common.scss";
</style>
