<template>
  <div style="padding: 0 36px;">
    <ul id="example-1">
      <li
        v-for="(value, name, index) in contents.front_end"
        :key="`${key}-${index}`"
      >
        {{ name }}: {{ value }}
      </li>
    </ul>
  </div>
</template>
<script>
import { mapState } from "vuex";

export default {
  name: "frontEnd",
  props: {
    activeTab: {
      type: String,
      required: false,
      default: ""
    }
  },
  computed: {
    ...mapState("loadedconfigurations", {
      isLoading: state => state.isLoading,
      contents: state => state.configurations
    })
  }
};
</script>

<style lang="scss" scoped>
$content-theme-color: var(--theme-color) !default;
$content-theme-hover-color: var(--theme-hover-color) !default;
$content-theme-row-hover-color: var(--theme-row-hover-color) !default;
$content-theme-outline-color: var(--theme-outline-color) !default;
@import "~@/styles/content-edit-info.scss";
@import "~@/styles/button.scss";
@import "~@/styles/form_common.scss";
</style>
